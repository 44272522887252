.content-wrapper {
    padding: 1rem;
    margin-top: 0rem;
}
.backbutton {
    width: 5rem;
    height: 2rem;
    border: 1px solid lightgray;
    margin-bottom: 1rem;
    cursor: pointer;
}
.form-wrapper {
    display: grid;
    grid-template-columns: 68% 30%;
    width: 100%;
    gap: 1rem;
    /* margin: 0 1rem; */
}
.left-side {
    /* display: flex; */
    display: grid;
    width: 100%;
}
.left-side header {
    padding: 0.6rem 1rem;
    display: flex;
    gap: 1rem;
    width: 100%;
    background-color: rgb(169, 212, 226);
}

.item {
    display: flex;
    align-items: center;
    gap: 0.3rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.item p,
.supplier-item p,
.supplier-cost-item p,
.margin-item p {
    color: grey;
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
}

.item h3,
.supplier-item h3,
.supplier-cost-item h3,
.margin-item h3 {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    word-break: break-all;
}
.supplier-name {
    background-color: rgba(238, 238, 238, 1);
    padding: 0.5rem;
}
.supplier-cost-item input {
    width: 5rem;
    height: 2rem;
    text-indent: 0.5rem;
    outline: none;
}
.supplier-name input {
    border: 1px solid grey;
    border-radius: 6px;
    position: relative;
    width: 150px;
    outline: none;
    text-indent: 10px;
    height: 2.5rem;
    /* margin: 10px; */
}
.supplier-name input:focus {
    outline: lightblue;
    border: 2px solid lightblue;
}
.supplier-contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
}
.supplier-item {
    display: grid;
    /* gap: 0.3rem; */
}
.supplier {
    display: grid;
    grid-template-columns: 60% 1fr;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    gap: 1rem;
    -webkit-box-shadow: 2px 6px 5px -3px rgba(0, 0, 0, 0.52);
    -moz-box-shadow: 2px 6px 5px -3px rgba(0, 0, 0, 0.52);
    box-shadow: 2px 6px 15px -3px rgba(0, 0, 0, 0.52);
}
.supplier-details {
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 1rem;
    align-items: baseline;
}
.supplier-cost {
    height: fit-content;
    display: grid;
    /* gap: 0.4rem; */
    background: lightcyan;
}
.supplier-cost-item {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}
.cost-wrapper {
    display: grid;
    gap: 1rem;
}
.supplier-cost-item-notes {
    background-color: bisque;
}
.supplier-right-side {
    padding: 1rem 0.5rem;
}
.supplier-right-side form {
    display: grid;
    gap: 1rem;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.form-control {
    display: grid;
    gap: 0.5rem;
}
.form-control input,
.form-control select {
    width: 100%;
    height: 2rem;
    text-indent: 0.5rem;
}
.right-side {
    box-shadow: 2px 6px 15px -3px rgba(0, 0, 0, 0.52);
    border-radius: 10px;
}
.right-side header {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    background-color: rgba(12, 12, 181, 0.601);
    color: white;
}
.right-side header p {
    font-size: 1.2rem;
    margin: 0;
}
.margin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(238, 238, 238, 1);
    /* padding: 1rem; */
}
.margin-item {
    align-items: center;
    display: flex;
    padding: 1rem;
    gap: 1rem;
}
.inputs {
    display: flex;
    /* display: flex; */
    /* justify-content: space-around; */
    flex-wrap: wrap;
    padding: 1rem;
    gap: 1rem;
}
.inputs button {
    padding: 0 1rem;
    border: none;
    /* margin: 0.5rem; */
    background-color: rgba(12, 12, 181, 0.601);
    color: white;
}
.inputs input {
    /* width: fit-content; */
    text-indent: 0.5rem;
    max-width: 100px;
    height: 2.5rem;
}
.table-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.table-wrapper {
    display: grid;
    justify-items: center;
    margin-top: 1rem;
    margin: 1rem;
    /* margin: 1rem 1rem 0 1rem; */
    /* min-width: 900px; */
    width: 98%;
    overflow: scroll;
}
.table-wrapper table {
    /* padding: 1rem; */
    width: 100%;
}
.table-wrapper thead tr {
    background-color: antiquewhite;
}
.table-wrapper tr th {
    padding: 1rem 0.5rem;
    margin: 0.5rem;
    border: 0.5px solid grey;
    font-size: 0.8rem;
    text-align: center;
    text-transform: uppercase;
}
.table-wrapper tbody tr {
    border: 0.5px solid grey;
}
.table-wrapper tbody tr td {
    text-align: center;
    padding: 1rem 0;
    font-size: 0.8rem;
    border: 0.5px solid grey;
}
.table-wrapper tbody tr td button {
    border: none;
    background-color: inherit;
}
@media screen and (max-width: 1260px) {
    .form-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    }
}
@media screen and (max-width: 800px) {
    .supplier,
    .supplier-details {
        gap: 1rem;
        grid-template-columns: 1fr;
    }
    .form-wrapper {
        grid-template-columns: 1fr;
        width: 100%;
    }
    .supplier-right-side form {
        grid-template-columns: 1fr;
    }
}
/* --------------------model----------------- */
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10000;
}
.modalContent {
    /* gap: 2rem; */
    opacity: 1;
    max-width: 90vw;

    /* width: 100vw; */
    /* margin-top: 7%;
 margin-left: 23%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    position: relative;
    overflow: scroll;
}
.product-table {
    width: 100%;
    max-height: 90vh;
    /* margin: 1rem; */
}
.modalContent table::-webkit-scrollbar {
    display: none !important;
}
.modalContent table {
    /* padding: 1rem; */
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    overflow: scroll;
}
.product-table thead tr th {
    background-color: antiquewhite;
    padding: 1rem;
    font-size: 0.9rem;
}
.product-table tbody tr td {
    padding: 1rem;
    font-size: 0.9rem;
    word-break: break-all;
}
.product-table tbody tr {
    cursor: pointer;
}
.product-table tbody tr:hover {
    cursor: pointer;
    background-color: lightgrey;
}
.add-item-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    padding: 0.2rem 1rem;
}
.modalContent .add-batch {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    justify-self: unset;

    align-self: initial;
}
.modalContent .add-batch p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 550;
    font-family: sans-serif;
    margin-left: 3rem;
}
.modalContent .add-batch button {
    padding: 0.5rem;
    border: none;
    margin: 0.5rem;
    background-color: rgba(12, 12, 181, 0.601);
    color: white;
}
/* ----------------------add batch info--------------- */
.error {
    color: red;
}
.modalContent-addbatch {
    opacity: 1;
    max-width: 600px;
    gap: 2rem;
    /* width: 100vw; */
    /* margin-top: 7%;
 margin-left: 23%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    position: relative;
    overflow: scroll;
}
.modalContent-addbatch form div {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.modalContent-addbatch form div label {
    text-transform: uppercase;
    /* font-weight: 550; */
    font-size: 1rem;
    font-family: sans-serif;
}
.submitHandler {
    display: grid;
    justify-items: center;
    align-items: center;
}
.submitHandler button {
    padding: 0.5rem;
    border: none;
    margin: 0.5rem;
    background-color: rgba(12, 12, 181, 0.601);
    color: white;
    border-radius: 10px;
}
.content-table-data button {
    border: 1px solid black;
}
.form-control-add {
    position: relative;
}

.form-control-add input {
    border: 1px solid gray;
    border-radius: 6px;
    position: relative;
    width: 100%;
    /* margin: 10px; */
    line-height: 6ex;
}

.form-control-add label {
    position: absolute;
    top: -10px;
    z-index: 1;
    font-size: 0.8rem;
    width: fit-content;
    left: 6px;
    background-color: white;
    padding: 0 5px;
}
.modal-spinner {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}
.spinner {
    display: grid;
    /* justify-items: center; */
    gap: 2rem;
    opacity: 1;
    /* max-width: 600px; */
    justify-items: center;
    width: 100%;
    padding: 2rem 0;
    /* background: #ffffff; */
    border-radius: 14px;
    position: relative;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}
.modal-content {
    display: grid;
    /* justify-items: center; */
    gap: 2rem;
    opacity: 1;
    max-width: 500px;
    width: 100%;
    padding: 0.5rem 0;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
}
.header {
    padding: 0rem 1rem 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cecaca;
}
.header p {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    font-family: "Open Sans", sans-serif;
}
.header button {
    border: none;
    background-color: inherit;
}
.spinner {
    display: grid;
    /* justify-items: center; */
    gap: 2rem;
    opacity: 1;
    /* max-width: 600px; */
    justify-items: center;
    width: 100%;
    padding: 2rem 0;
    /* background: #ffffff; */
    border-radius: 14px;
    position: relative;
}
.modal-content form {
    display: grid;
    gap: 0.5rem;
    align-items: center;
}
.form-control {
    padding: 0 5rem 1rem 5rem;
    display: flex;
    gap: 1rem;

    border-bottom: 1px solid #cecaca;
}
.form-control label {
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 550;
}
.form-control textarea {
    flex: 2;
    /* width: 100%; */
    padding: 0.5rem;
    font-family: "Open Sans", sans-serif;
    font-size: 0.9rem;
    border: 1px solid #cecaca;
    outline: none;
}
.control-btns {
    justify-self: right;
    padding-right: 2rem;
    display: flex;
    gap: 1rem;
}
.control-btns button {
    font-size: 1rem;
    padding: 0.1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-items: center;
}
.submit-btn {
    background-color: blue;
    color: white;
    border: 1px solid blue;
}
.delete-btn {
    color: black;
    border: 1px solid #cecaca;
}
@media screen and (max-width: 800px) {
    .modal-content {
        gap: 1rem;
        margin: 1rem;
        height: fit-content;
        padding: 0.1rem;
    }
}
@media screen and (max-width: 400px) {
    .modal-content {
        max-width: 100%;
    }
}

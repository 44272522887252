.dashboard-wrapper {
    display: flex;
}
.dashboard-leftside {
    background-color: white !important;
    min-height: 100vh;
    /* height: 100%; */
}
.dashboard-rightside {
    flex: 2;
}

@media screen and (max-width: 786px) {
    .dashboard-wrapper {
        grid-template-columns: 3.7rem 1fr;
    }
}
.master-product-datefilter {
    grid-column: 1/-1;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.master-product-datefilter input {
    width: 100%;
    max-width: 240px;
    text-indent: 0.3rem !important;
    height: 3rem !important;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
}
.master-product-datefilter button {
    width: 100%;
    max-width: 150px;
}
.master-product-datefilter div {
    position: relative;
    display: flex;
    flex-direction: column;
}
.master-product-datefilter div:focus-within label {
    transform: translate(0, 5px) scale(0.8);
    color: #0a53e4;
}

.master-product-datefilter div .filled {
    transform: translate(0, 5px) scale(0.8);
}

.master-product-datefilter div label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 18px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #ababab;
    font-size: 14px;
    line-height: 1;
    left: 16px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
}
.master-product-datefilter div label[title="date"] {
    transform: translate(0, 5px) scale(0.8);
}
.master-product-datefilter div label span {
    color: red;
}
/* ---------------------------dashboard------------ */
.dash-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

    gap: 2rem;
}
.dash-Totalsales {
    background-color: white !important;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    border: none;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0px 4px 60px rgb(0 0 0 / 8%);
    /* min-width: 25rem; */
    max-width: 25rem;
    /* min-width: 25rem; */
    height: fit-content;
}
.add-product {
    display: flex;
    justify-content: space-between;
}
.dash-TotalStockValue {
    border: none;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0px 4px 60px rgb(0 0 0 / 8%);
    max-width: 25rem;
    height: fit-content;
    background-color: white !important;
    display: grid;
    gap: 1rem;
    max-height: 20rem;
    overflow-y: scroll;
    min-width: 20rem;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.stock-value {
    width: 100%;
}
.stock-value table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    overflow: scroll;
}
.stock-value table th,
.stock-value table tr td {
    padding: 0 1rem;
}
.dash-TotalStockValue::-webkit-scrollbar {
    display: none;
}
.dash-Totalsales form {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}
.dash-Totalsales form .date-control {
    display: grid;
    gap: 0.5rem;
}
.dash-Totalsales form button {
    display: grid;
    align-items: center;
    justify-items: center;
    /* height: fit-content; */
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: none;
    background-color: orange;
    border-radius: 50%;
}
.dash-Totalsales select {
    /* align-self: ; */
    justify-self: right;
    text-indent: 0.5rem;
    outline: none;
    border-radius: 5px;
}
.icon {
    padding: 0.5rem;
    border-radius: 50%;
    width: fit-content;
    background-color: rgb(222, 199, 155);
}
.totalsales-count {
    display: flex;
    flex-direction: column;
}
.totalsales-count p {
    margin: 0;
    font-size: 1.2rem;
}
.sales-one {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.cancelled {
    display: grid;
    color: red;
    margin: 0 !important;
    border: 1px solid red;
    padding: 0.3rem 0.5rem;
    font-weight: 600;
}
/* ----------category------------ */

.CategoryWrapper {
    /* margin-top: 4rem; */
    display: grid;
    gap: 1rem;
    padding: 1rem;
}
.CategoryWrapper > h3 {
    color: rgba(36, 38, 45, 1);
    font-weight: 1rem;
}
.category-form .form-control input {
    color: red;
}
.category-form,
.customer-form,
.category-form-two {
    background-color: white !important;
    display: grid;
    grid-template-columns: 1fr 0.7fr 0.5fr;
    gap: 1rem;
    border: 1px solid #d5d6d7;
    border-radius: 10px;
    padding: 1rem 2rem 1rem 2rem;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}

.details-poitems {
    background-color: white !important;
    /* margin-top: 1rem; */
    display: grid;
    gap: 1rem;
    border: 1px solid #d5d6d7;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}
.details-poitem {
    display: flex;
    gap: 0.3rem;
    align-items: center;
}
.details-poitem h3 {
    font-size: 1.1rem;
    margin: 0;
}
.details-poitem p {
    margin: 0;
    font-size: 1rem;
}
.category-form-two {
    grid-template-columns: 70% 30%;

    /* padding: 1rem; */
    /* margin-right: 1rem; */
}
.customer-form {
    grid-template-columns: 1fr;
}
.category-form input,
.customer-form input,
.category-form-two input,
.category-form select {
    height: 3rem;
    text-indent: 1rem;
    outline: none;
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;

    border: 1px solid rgba(229, 231, 235, 1);
}
.category-form select {
    height: 2.85rem;
}
.item-image-upload .select input[type="number"]::-webkit-outer-spin-button,
.item-image-upload .select input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.item-image-upload .select input[type="number"] {
    -moz-appearance: textfield;
}
.category-form input:focus,
.customer-form input:focus,
.category-form select:focus {
    background-color: white;
}
.category-form button,
.category-form-two button,
.add-product button {
    outline: none;
    /* padding-right: 1rem; */
    width: 100%;
    height: 3rem;
    background-color: rgba(14, 159, 110, 1);
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 500ms;
}
.add-product button {
    width: 9rem;
    /* padding: 0 1.5rem; */
    height: 2rem;
}
.tags {
    max-width: 500px;
    width: 100%;
}

.category-form-two button {
    margin-right: 1rem;
}
.category-form button:hover {
    background-color: #0d8b61;
}

.cat-tableicon {
    background-color: #f9fafb;
    border: 1px solid #d5d6d7;
    border-radius: 50%;
    width: 2rem;
    text-align: center;
    height: 2rem;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.cat-tableicon img {
    width: 1.4rem;
}
.cat-type {
    display: flex;
    grid-template-columns: repeat(5, 1fr);
    justify-items: left;
    gap: 1rem;
    row-gap: 0.5rem;
}
.cat-type p {
    text-transform: capitalize;
    margin: 0;
    padding: 0;
    color: #707275;
    background-color: #e5e7eb;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    /* padding: 0 0; */
    padding: 0.1rem 0.3rem;
}

.status_inactive {
    color: #f05252;
    font-size: 0.8rem;
    width: fit-content;
    margin: 0;
    padding: 0;
    display: grid;
    justify-items: center;
    align-items: center;
    background-color: #fde8e8;
    padding: 0.5rem;
    border-radius: 10px;
    text-align: center;
    /* text-transform: ; */
}
.status_active {
    color: #0e9f6e;
    font-size: 0.8rem;
    width: fit-content;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #def7ec;
    padding: 0.5rem;
    border-radius: 10px;
}
.uploaded-img {
    display: grid;
    justify-items: center;

    width: 80%;
}
@media screen and (max-width: 1000px) {
    .category-form,
    .category-form-two {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 767px) {
    .category-form,
    .customer-form,
    .category-form-two {
        padding: 1rem;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 14px;
}

/* Hide default HTML checkbox */

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 0px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #0d8b61;
}

input:focus + .slider {
    box-shadow: 0 0 1px #0d8b61;
}

input:checked + .slider:before {
    -webkit-transform: translateX(11px);
    -ms-transform: translateX(11px);
    transform: translateX(11px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.tablebtns,
.view,
.tablebtnsview {
    display: flex;
    gap: 0.6rem !important;
    align-items: center;
    /* justify-content: center; */
    /* margin-left: 0.5rem; */
}
.tablebtnsview {
    margin: 0;
}
.view {
    justify-content: center;
}
.tablebtns button,
.view button,
.tablebtnsview button {
    border: none;
    background-color: white;
    color: grey !important;
    outline: none;
}
.tablebtnsview button:disabled {
    color: lightgrey !important;
}
@media screen and (max-width: 767px) {
    .CategoryWrapper {
        padding: 1rem 0.5rem;
    }
    .category-form {
        padding: 1rem;
    }
}
/* -------------products---------- */

.product-form {
    background-color: white !important;
    /* margin-top: 1rem; */
    display: grid;
    grid-template-columns: 32% 25% 20% 18%;
    gap: 1rem;
    border: 1px solid #d5d6d7;
    border-radius: 10px;
    padding: 2rem 1rem;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}
.product-form input,
.product-form select {
    height: 3rem;
    text-indent: 1rem;
    outline: none;
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;
    border: 1px solid rgba(229, 231, 235, 1);
}
.product-form input:focus,
.product-form select:focus {
    background-color: white;
}
.product-form button {
    height: 3rem;
    background-color: rgba(14, 159, 110, 1);
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: 500ms;
}
.product-form button:hover {
    background-color: #0d8b61;
}

.file-uploader-wrapper {
    display: grid;
    grid-template-columns: 67% 30%;
    gap: 1rem;
    border-radius: 10px;
    padding: 1.1rem 1rem;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
    background-color: white;
    border: 1px solid #d5d6d7;
}
.file-uploader-wrapper .upload-btns {
    display: flex;
    gap: 1rem;
    width: 100%;
}
.file-uploader-wrapper .upload-btns button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 1rem;
    border-radius: 10px;
    width: 100%;
}
.upload-file {
    border: 1px dashed rgba(14, 159, 110, 1);
    width: 100%;
    padding: 0.7rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.upload-desc p,
.uploaded-file p {
    margin: 0;
    color: #707275;
    font-size: 1rem;
}
.upload-btn {
    color: #4c4f52;
    background-color: rgba(229, 231, 235, 0.6);
}
.download-btn {
    background-color: rgba(14, 159, 110, 1);
    color: white;
}
.download-btn:hover {
    background-color: #0d8b61;
}
.idformatter,
.percentage {
    font-weight: 700;
    font-size: 0.8rem;
    margin: 0;
}
.percentage {
    font-size: 0.9rem;
}
.productsname {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.productsname p {
    margin: 0;
}
.priceformatter {
    font-weight: 700;
    font-size: 1rem;
    margin: 0;
}
.Selling {
    color: #0e9f6e;
    font-size: 0.8rem;
    width: fit-content;
    margin: 0;
    padding: 0;
    text-align: center;
}
.price-wrapper {
    display: grid;
    align-items: center;
}
.Selling-wrapper {
    display: grid;
    justify-items: center;
    align-items: center;
    background-color: #def7ec;
    padding: 0.2rem 0;
    border-radius: 10px;
}
.detail-formatter {
    margin-left: 1rem;
}
.detail-formatter button {
    border: none;
    outline: none;
    background-color: white;
    color: #d5d6d7;
}
@media screen and (max-width: 1100px) {
    .product-form {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 800px) {
    .file-uploader-wrapper {
        grid-template-columns: 1fr;
    }
    .file-uploader-wrapper .upload-btns button {
        padding: 1rem 0;
    }
}
/* --------------orders------------ */
.orders-form {
    background-color: white !important;
    /* margin-top: 1rem; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    border: 1px solid #d5d6d7;
    border-radius: 10px;
    padding: 2rem 1rem;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}
.orders-navbar,
.user-details-nav {
    border: 1px solid #d5d6d7;
    background-color: white !important;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
    display: flex;
    border-radius: 10px;
    gap: 2rem;
    /* padding: 1rem; */
}
.user-details-nav {
    background-color: inherit !important;
    border: none;
    /* border-bottom: 1px solid #d5d6d7; */
    /* box-shadow: none; */
}
.orders-navbar button,
.user-details-nav button {
    background-color: inherit;
    border: none;
    cursor: pointer;
    padding: 1rem;
}
.order-active {
    border-bottom: 1px solid #0080ff !important;
    color: #0080ff;
}
.orders-form input,
.orders-form select {
    height: 3rem;
    text-indent: 1rem;
    outline: none;
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;
    border: 1px solid rgba(229, 231, 235, 1);
}
.orders-form input:focus,
.orders-form select:focus {
    background-color: white;
}
.orders-form button {
    height: 3rem;
    background-color: rgba(14, 159, 110, 1);
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: 500ms;
}
.orders-form button:hover {
    background-color: #0d8b61;
}
.method {
    font-weight: 700;
    font-size: 0.9rem;
    margin: 0;
    text-transform: capitalize;
}

.delivered {
    color: #0e9f6e;
    font-size: 0.8rem;
    width: fit-content;
    margin: 0;
    padding: 0;
    text-align: center;
}
.processing {
    color: #3f83f8;
    font-size: 0.8rem;
    width: fit-content;
    margin: 0;
    padding: 0;
    text-align: center;
    /* padding: 0.5rem 0; */
}
.processing-wrapper,
.cancel-wrapper,
.delivered-wrapper,
.pending-wrapper {
    display: grid;
    justify-items: center;
    align-items: center;
    background-color: #e1effe;
    padding: 0.2rem 0.5rem;
    border-radius: 10px;
    width: fit-content;
}
.delivered-wrapper {
    background-color: #def7ec;
}
.cancel-wrapper {
    background-color: #fde8e8;
}
.pending-wrapper {
    background-color: #fdf6b2;
}
.pending,
.cancel {
    color: #c27803;
    font-size: 0.8rem;
    width: fit-content;
    margin: 0;
    padding: 0;
    text-align: center;
}
.cancel {
    color: #f05252;
}
.orderstatus select {
    height: 2rem;
    text-indent: 0.5rem;
    outline: none;
    width: 90%;
    /* font-sizcancele: 1rem; */
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;

    border: 1px solid rgba(229, 231, 235, 1);
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
}
.order-handler {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
}
.order-handler button {
    color: white;
    background-color: #0e9f6e;
    border: none;
    font-size: 1.1rem;
    padding: 0.5rem 2rem;
    cursor: pointer;

    border-radius: 10px;
}
@media screen and (max-width: 1130px) {
    .orders-form {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 767px) {
    .orders-navbar {
        gap: 0.4rem;
    }
    .orders-navbar button {
        padding: 0.5rem;
        font-size: 10px;
    }
}
.expired-wrapper {
    display: grid;
    justify-items: center;
    align-items: center;
    background-color: #fde8e8;
    padding: 0.2rem 0;
    border-radius: 10px;
}

.expired {
    color: #f05252;
    font-size: 0.8rem;
    width: fit-content;
    margin: 0;
    padding: 0;
    text-align: center;
}
/* ------------modal -------------- */
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    animation: linear normal forwards;
    animation-name: run;
    animation-duration: 100ms;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
    transition: 100ms;
}
.modal-content {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    opacity: 1;
    /* max-width: 700px; */
    overflow: scroll;
    min-width: 700px;
    width: fit-content;
    background: #ffffff;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    position: relative;
}
.modal-content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
/* .modal-content: */
.modal-content-header {
    padding: 1rem 1.5rem;
    font-weight: 500;
    background-color: #f9fafb;
    font-family: sans-serif;
    display: flex;
    justify-content: space-between;
}
.customer-info {
    padding: 1rem 1rem;
    display: grid;
    gap: 1rem;
    border-bottom: 1px solid #d5d6d7;
}
.customer-info button {
    width: fit-content;
    padding: 0.5rem 1rem;
    /* justify-self: right; */
    /* margin: 0 0 1rem 1.5rem; */
    border: none;
    background-color: red;
    color: white;
}
.customer-info div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1.5rem;
    /* padding: 0 0 0 2rem; */
}
.customer-info div p {
    font-weight: 550;
    margin: 0;
}
.customer-info h3 {
    padding: 0rem 1.5rem;
    font-size: 1.4rem;
    margin: 0;
    padding: 0;
}
.modal-content-header h3 {
    font-size: 1.3rem;
}
.modal-content-header p {
    font-size: 0.9rem;
}
.courier-info {
    padding: 1rem;
    width: 100%;
    display: grid;
    gap: 0.5rem;
    /* border-bottom: 1px solid #d5d6d7; */
}
.courier-info table {
    width: 100%;
}
.courier-info table thead {
    background-color: lightgrey;
}
.courier-info table th {
    font-size: 1.1rem;
    font-weight: 550;
}
.courier-info table tr td,
.courier-info table th {
    padding: 0.5rem;
    text-align: center;
}
.courier-info h3 {
    font-size: 1.4rem;
}
.close-btn {
    align-self: center;
    background-color: white;
    border: 1px solid white;
    outline: none;
    color: red !important;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 1);
}
.price-wrapper-product {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
}
.price-wrapper-product input {
    width: 100%;
}
.add-items {
    display: grid;
    /* height: 100%; */
    gap: 2rem;
    padding: 1rem;
}
.add-items-forms {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem 0;
    height: 100%;
}
.add-item {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 0.5rem;
    width: 100%;
}
.add-item .upload-icon {
    color: #0e9f6e;
    font-size: 2rem;
}
.add-item h5 {
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
.add-item p {
    color: #9e9e9e;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
.item-image-upload label,
.item-tag-input label {
    font-family: sans-serif;
    color: #24262d;
    font-size: 14px;
}
.item-image-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
}
.price-wrapper-product .item-image-upload {
    gap: 0.5rem;
}
.upload-image {
    border: 2px dashed #d5d6d7;
    width: 70%;
    height: 9rem;
    display: grid;
    align-items: center;
    cursor: pointer;
}
.dimension-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    max-width: 700px;
    gap: 1rem;
}
.dimension-wrapper input,
.dimension-wrapper .item-image-upload .select {
    width: 100%;
}

.dimension-wrapper .item-image-upload {
    display: grid;
    grid-template-columns: 25% 1fr;
    width: 100%;
}
.item-image-upload select,
.item-image-upload input {
    height: 3rem;
    text-indent: 1rem;
    outline: none;
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;

    border: 1px solid rgba(229, 231, 235, 1);
}
.item-image-upload textarea {
    text-indent: 1rem;
    outline: none;
    width: 100%;
    padding: 0.5rem 0;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;
    resize: none;
    border: 1px solid rgba(229, 231, 235, 1);
}
.item-image-upload select:focus,
.item-image-upload input:focus,
.item-image-upload textarea:focus {
    background-color: white;
}
.error {
    color: rgba(249, 128, 128, 1);
    font-size: 1rem;
    font-weight: 1rem;
    padding-bottom: 0;
    margin-bottom: 0;
}
.item-image-upload .select {
    width: 70%;
}
.price-wrapper-product .item-image-upload .select {
    width: 100%;
}
.item-tag-input {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
}
.item-tag {
    width: 69%;
    /* padding: 0.4rem 1rem; */
    border-radius: 10px;
    min-height: 3rem;
    border: 1px solid rgba(229, 231, 235, 1);
}
.item-tag input {
    text-indent: 1rem;
}
.add-btn {
    margin: 0 1rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}
.add-btn button {
    width: 100%;
    border: none;
    /* padding: 1rem 0; */
    height: 2.5rem;
    border-radius: 10px;
    color: red;
}
.add-category-btn {
    background-color: rgba(14, 159, 110, 1);
    color: white !important;
}
.courier-info .add-btn {
    margin-top: 2rem;
    justify-self: center;
}
.courier-info .add-btn button {
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
}
.loader-price {
    background-color: rgba(14, 159, 110, 0.5);
    width: 40%;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
}
.loader-price p {
    margin: 0;
    /* color: ; */
}
@keyframes run {
    0% {
        right: -10rem;
    }
    100% {
        right: 0rem;
    }
}
@media screen and (max-width: 767px) {
    .dimension-wrapper,
    .dimension-wrapper .item-image-upload {
        grid-template-columns: 1fr;
    }
    .dimension-wrapper .item-image-upload {
        align-items: center;
        justify-items: center;
    }
    .modal-content {
        width: 85%;
        min-width: 80%;
    }
    .modal-content-header p {
        font-size: 0.8rem;
    }
    .item-image-upload {
        gap: 0.6rem;
        flex-direction: column;
    }
    .upload-image {
        width: 100%;
    }
    .item-image-upload label,
    .item-tag-input label {
        font-size: 17px;
    }
    .item-image-upload .select {
        width: 100%;
    }
    .add-items {
        gap: 1rem;
    }
    .item-tag-input {
        gap: 1rem;
        flex-direction: column;
    }
    .item-tag {
        width: 100%;
    }
}
/* -----------settings--------------- */
.Container-report {
    display: grid;
}
.report-form-wraper {
    justify-self: center;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
    max-width: 800px;
    width: 100%;
    display: grid;
}
.edit-profile .item-image-upload {
    display: grid;
    justify-content: unset;
}
.edit-profile {
    display: grid;
    /* margin: 0rem 6rem; */
    justify-self: center;
    max-width: 700px;
    width: 100%;
    padding: 2rem 1rem;
    gap: 2rem;
}
.edit-profile label {
    width: 100%;
    color: #24262d;
    font-size: 1.1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.edit-profile-formControl {
    display: grid;
    gap: 0.5rem;
    /* grid-template-columns: 20% 80%; */

    padding: 0 1rem;
}
/* . */
.edit-profile-formControl input,
.edit-profile-formControl select {
    width: 100%;
    height: 3rem;
    text-indent: 1rem;
    outline: none;
    width: 100%;
    height: 3rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;

    border: 1px solid rgba(229, 231, 235, 1);
}

.edit-profile-formControl input:focus {
    background-color: white;
}
/* Chrome, Safari, Edge, Opera */
.edit-profile-formControl input::-webkit-outer-spin-button,
.edit-profile-formControl input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.edit-profile-formControl input[type="number"] {
    -moz-appearance: textfield;
}
.edit-profile input[type="submit"] {
    justify-self: center;
    padding: 0.7rem 2rem;
    background-color: rgba(14, 159, 110, 1);
    /* margin-right: 1rem; */
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: 500ms;
}
.edit-profile input[type="submit"]:hover {
    background-color: #0d8b61;
}
@media screen and (max-width: 767px) {
    .edit-profile {
        padding: 1rem;
        margin: 0 0.5rem;
        gap: 1rem;
    }
    .edit-profile-formControl {
        display: grid;
        justify-content: unset;
        gap: 1rem;
        width: 100%;
    }
    .edit-profile-input {
        width: 100%;
    }
    .edit-profile .item-image-upload {
        display: grid;
        justify-content: unset;
    }
    .edit-profile .upload-image {
        width: 100%;
    }
    .add-item p {
        font-size: 0.5rem;
    }
}
/* ---------loader ----------- */

.loader {
    /* margin-left: 15rem; */
    display: grid;
    align-items: center;
    justify-items: center;
}
.users-details-wrapper {
    margin-left: 2rem;
    display: grid;
    gap: 1rem;
}
.user-details {
    display: grid;
    grid-template-columns: 30% 4% 66%;
    align-items: center;
    font-size: 1rem;
}
.user-details h4,
.user-details h5 {
    font-size: 1.2rem;
    margin: 0;
}
.user-details p {
    margin: 0;
}
.user-details a {
    background-color: inherit;
    border: none;
    font-size: 2.5rem;
    cursor: pointer;
    /* color: black; */
}
.file-imgs {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    /* grid-template-columns: (repeat(auto-fit, minmax(2.6rem, 1fr))); */
    width: 100%;
    justify-items: left;
    align-items: center;
}
@media screen and (max-width: 767px) {
    .user-details {
        grid-template-columns: 45% 5% 50%;
    }
    .user-details h4,
    .user-details h5 {
        font-size: 1rem;
        margin: 0;
    }
    .user-details p {
        font-size: 0.8rem;
    }
    .users-details-wrapper {
        margin-left: 0;
    }
}
/* ---------------order details ----------------- */
.order-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.order-header-item {
    display: grid;
    align-items: center;
}
.order-header-item h5 {
    color: #4c4f52;
    font-weight: 700;
    font-family: sans-serif;
    font-size: 1rem !important;
    margin: 0;
}

.order-status-wrapper {
    display: grid;
    justify-items: center;
    align-items: center;
    justify-self: right;
    padding: 0.2rem 1rem !important;
    border-radius: 10px;
    width: fit-content;
}
.order-status-wrapper p {
    color: white;
    margin-bottom: 0;
    font-size: 1rem;
}
.order-pending-wrapper {
    background-color: #d0c02f;
}
.order-delivered-wrapper {
    background-color: #227617;
}
.order-returned-wrapper {
    background-color: #db2d2d;
}
.order-header-item p {
    color: #707275;
    font-family: sans-serif;
    font-size: 1rem;
    margin: 0;
    padding: 0;
}
.orders-form-invoice-wrapper {
    background-color: white !important;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    border: 1px solid #d5d6d7;
    border-radius: 10px;
    padding: 1rem 1rem;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}
.orders-form-invoice-wrapper h3 {
    margin: 0;
}
.customer-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.customer-info-header h4 {
    margin: 0;
}
.invoice-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 1rem 1rem 1rem; */
    border-bottom: 1px solid #d5d6d7;
    padding-bottom: 1rem;
}
.detail-status {
    display: flex;
    align-items: center;
    align-self: center;
    gap: 1rem;
    padding: 1rem 0;
}
.detail-status select {
    height: 2.5rem;
    text-indent: 0.5rem;
    outline: none;
}
.detail-status button,
.add-shipment {
    color: white;
    outline: none;
    background-color: #0e9f6e;
    border: none;
    font-size: 0.8rem;
    width: 8rem;
    height: 2rem;
    /* padding: 0.5rem rem; */
    cursor: pointer;

    border-radius: 10px;
}
.add-shipment {
    margin: 1rem 0;
    justify-self: right;
}
.invocie-detail-item {
    display: grid;
    /* gap: 0.5rem; */
}
.invocie-detail-item h3 {
    font-size: 1.3rem;
    font-weight: 650;
}
.detail-status span {
    color: #707275;
    font-size: 1.1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 550;
}
.invocie-detail-address img {
    width: 100px;
}
.order-items {
    /* padding: 1rem 0 0 0; */
    display: grid;
    gap: 1rem;
}
.order-item-data {
    padding-bottom: 1.5rem;
    display: grid;

    border-bottom: 1px solid #d5d6d7 !important;
}
.order-date,
.order-date-address {
    display: grid;
    grid-template-columns: 21% 4% 75%;
    align-items: center;
    /* font-size: 1.2rem; */
}
.order-date-address {
    align-items: unset;
    margin-top: 1rem;
}
.order-date {
    display: grid;
    gap: 0rem;
}
.order-date h5 {
    color: #4c4f52;
    font-weight: 700;
    font-family: sans-serif;
    font-size: 1rem !important;
}
.order-date p,
.order-date a {
    color: #707275;
    font-family: sans-serif;
    font-size: 1rem;
    margin: 0;
    padding: 0;
}
.product-search {
    position: relative;
}
.product-items {
    position: absolute;
    border-radius: 10px;
    height: 300px;
    overflow: scroll;
    top: 3.1rem;
    width: 100%;
    background-color: white;
    border: 1px solid rgba(229, 231, 235, 1);
    display: flex;
    flex-direction: column;
    justify-items: left;
    overflow-x: hidden;
    box-shadow: 2px 6px 21px -2px rgba(0, 0, 0, 0.75);
}
.product-items button {
    padding: 0.2rem 0 0.2rem 0.4rem;
    cursor: pointer;
    width: 100%;
    text-align: left;
    justify-self: left;
    border: none;
    word-wrap: break-word;
    white-space: normal;
    word-wrap: break-word;
    background-color: white;
}
.product-items button:hover {
    background-color: lightgrey;
}
.order-item {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 300px));
    gap: 0rem;
    row-gap: 1.5rem;
}
.order-item img {
    width: 260px;
}
.order-item input {
    max-width: 200px;
    text-indent: 0.5rem;
    height: 2.5rem;
}
.order-desc {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.order-desc h3 {
    /* font-family: "Jost"; */
    font-style: normal;
    font-size: 1.3rem;
    letter-spacing: 0.01em;
    color: #103178;
    font-weight: 600;
}
.order-desc h3 span {
    font-size: 1rem;
    color: #5c557d;
}
.order-desc p {
    padding: 0;
    margin: 0.2rem;
    color: #5c557d;
}
.order-desc p span {
    color: green;
}
@media screen and (max-width: 767px) {
    .orders-form-invoice-wrapper {
        overflow: scroll;
        min-width: 700px;
    }
    /* .order-item {
        grid-template-columns: 1fr;
    } */
}
/* -----------------------roles------------------ */

.roles-wrapper {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 0.5rem;
    width: 100%;
}
.roles-leftside,
.roles-rightside {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    height: 100%;
    min-height: 73vh;
    max-height: 73vh;
    overflow-y: scroll;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}
.roles-leftside::-webkit-scrollbar {
    width: 20px;
}

.roles-leftside::-webkit-scrollbar-track {
    background-color: transparent;
}

.roles-leftside::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

.roles-leftside::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}
.roles-leftside header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.roles-leftside header h3 {
    font-size: 1.1rem;
}
.roles-leftside header button,
.role-operations button {
    /* width: 6rem; */
    padding: 0.5rem 1rem;
    /* height: 3rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(14, 159, 110, 1);
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 500ms;
}
.edit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(14, 159, 110, 1);
    color: white;
    font-family: inherit;
    border: none;
    /* border-radius: 10px; */
    font-size: 1.1rem;
    cursor: pointer;
    transition: 500ms;
}
.role-operations button {
    width: 100%;
    /* margin-top: 1rem; */
    justify-self: center;
}
.roles-leftside header button:hover {
    background-color: #0d8b61;
}
.roles-leftside button {
    text-align: left;
    padding-left: 0.5rem;
}
.roles-leftside > div {
    display: grid;
    grid-template-columns: 60% 20% 20%;
}
.roles-leftside .role-btn {
    font-weight: 550;
    padding: 0.5rem;
    border: 1px solid #d8d0d0;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}
.admin-btn-active {
    color: white;
    background-color: #104070;
}
.add-btn-inactive {
    color: black;
    background-color: #9e9e9e;
}
.del-btn {
    background-color: red;
    border: none;
    color: white;
}
.roles-rightside {
    padding: 0;
    gap: 1rem;
}
.roles-rightside header {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    background-color: #93bae0;
}
.roles-rightside header h3 {
    font-size: 1.1rem;
    color: black;
}
.role-operations > h3 {
    font-size: 1.3rem;
}
.role-operations {
    display: grid;
    /* gap: 1rem; */
}
.role-operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
}
.role-operation p {
    margin: 0;
    padding: 0;
}
.grey-bg {
    padding: 0.5rem;
    background-color: rgba(238, 238, 238, 1);
}
.white-bg {
    padding: 0.5rem;
    background-color: white;
}
.role-slider .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 19px;
}

/* Hide default HTML checkbox */

.role-slider .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.role-slider .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.role-slider .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 0px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.role-slider input:checked + .slider {
    background-color: #0d8b61;
}

.role-slider input:focus + .slider {
    box-shadow: 0 0 1px #0d8b61;
}

.role-slider input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
}

/* Rounded sliders */
.role-slider .slider.round {
    border-radius: 34px;
}

.role-slider .slider.round:before {
    border-radius: 50%;
}
@media screen and (max-width: 1160px) {
    .roles-wrapper {
        grid-template-columns: 1fr;
    }
    .roles-leftside,
    .roles-rightside {
        min-height: 100%;
        max-height: 100%;
    }
}
.tablebtns > ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
}

/* .menu > li {
    float: left;
} */
.menu button {
    border: 0;
    background: transparent;
    cursor: pointer;
}
.menu button:hover,
.menu button:focus {
    outline: 0;
    text-decoration: underline;
}

.submenu {
    list-style: none;
    margin-bottom: 0;
    display: none;
    position: absolute;
    padding: 0;
    text-align: left;
    background-color: lightgray;
    list-style-type: none;
}
.submenu li {
    text-decoration: none;
}
.submenu button {
    padding: 0.5rem;
    width: fit-content;
    margin: 0;
    width: 100%;
    /* background-color: white; */
}
.submenu button:hover {
    background-color: white;
}
.menu button:focus + .submenu,
.submenu:hover {
    display: block;
}
/* ---------------------order-return-modal -------- */
.return-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10000;
}
.return-modalContent {
    /* gap: 2rem; */
    opacity: 1;
    max-width: 50vw;
    padding: 0.5rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    background: #ffffff;
    position: relative;
    overflow-y: scroll;
    height: 80vh;
}
.select-option-items {
    display: flex;
    /* border: ; */

    border-bottom: 1px solid #d5d6d7 !important;
    justify-content: space-between;
}
.select-option-items button {
    height: fit-content;
    /* align-self: center; */
    justify-self: center;
    color: white;
    /* background-color: green; */
    border: none;
    padding: 0.5rem 1rem;
}
.red-btn {
    background-color: red;
}
.green-btn {
    background-color: green;
}
.return-modalContent .order-item-data {
    border-bottom: none !important;
}
.return-modalContent .order-item-data > button {
    height: fit-content;
    /* align-self: center; */
    /* justify-self: center; */

    border: none;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    /* margin-bottom: 1rem; */
    color: white;
}
.return-modalContent .select-option-items:last-child {
    border-bottom: none !important;
}
.process,
.return-modalContent .submit-btn {
    background-color: green;
}
.remove {
    background-color: red !important;
}
.shipment-modalContent {
    /* gap: 2rem; */
    opacity: 1;
    max-width: 65vw;
    width: fit-content;
    /* width: 100vw; */
    /* margin-top: 7%;
 margin-left: 23%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    position: relative;
    overflow: scroll;
}
.shipment-Details {
    width: 100%;
    max-height: 90vh;
}
.shipment-Details table::-webkit-scrollbar {
    display: none !important;
}
.shipment-Details table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    overflow: scroll;
}
.shipment-Details table thead tr th {
    background-color: antiquewhite;
    padding: 1rem;
    font-size: 0.9rem;
}
.shipment-Details table tbody tr td {
    padding: 1rem;
    font-size: 0.9rem;
    word-break: break-all;
}
.shipment-Details tbody tr {
    cursor: pointer;
}
.shipment-Details tbody tr:hover {
    cursor: pointer;
    /* background-color: lightgrey; */
}
.shipment-Details tbody tr td button {
    border: none;
    background-color: inherit;
}
.shipment-Details tbody tr td button:hover {
    color: red;
}
.form-control-add {
    position: relative;
}

.form-control-add input {
    border: 1px solid rgba(229, 231, 235, 1);
    border-radius: 6px;
    position: relative;
    width: 100%;
    min-width: 10rem;
    text-indent: 0.3rem;
    /* margin: 10px; */
    line-height: 6ex;
}

.form-control-add label {
    position: absolute;
    top: -10px;
    z-index: 1;
    font-size: 0.8rem;
    width: fit-content;
    left: 6px;
    background-color: white;
    padding: 0 5px;
}
.supplier-payment-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 0.5rem;
}
.supplier-payment-filters button {
    width: fit-content;
    padding: 1rem 3rem;
    display: flex;
    align-items: center;
}
.print-btn {
    background-color: inherit;
    border: none;
    color: red;
}
.print-btn:hover {
    text-decoration: underline;
}

.uploaded-data {
    display: grid;
    width: 500px;
    gap: 1rem;
    justify-items: right;
}
.upload-image-user {
    border: 2px dashed #d5d6d7;
    padding: 1rem 0;
    width: 90%;
    height: 9rem;
    display: grid;
    align-items: center;
    cursor: pointer;
}
.uploaded-img-user {
    justify-self: right;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    row-gap: 1rem;
    width: 90%;
    gap: 1rem;
}
.uploaded-name {
    position: relative;
    width: fit-content;
    /* padding: 1rem; */
}
.uploaded-name img {
    width: 150px;
}
.uploaded-name button {
    right: -0.5rem;
    position: absolute;
    top: -0.5rem;
    background-color: #be7f00;
    border-radius: 50%;
    border: none;
    outline: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.update-user-form label {
    align-self: flex-start;
}
.order-return-input {
    display: flex;
    gap: 1rem;
    align-items: baseline;
}
.order-return-input input {
    height: 2.5rem;
    text-indent: 0.5rem;
}
.internalTransfer {
    display: flex;
    justify-content: space-between;
}
.internal-transfer-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.internal-transfer-heading button {
    outline: none;
    /* padding-right: 1rem; */
    width: 8rem;
    height: 3rem;
    background-color: rgba(14, 159, 110, 1);
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 500ms;
}

.internalTransfer button,
.map-btn {
    outline: none;
    width: 100%;
    max-width: 9rem;
    height: 3rem;
    background-color: rgba(14, 159, 110, 1);
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: 500ms;
}
@media screen and (max-width: 1270px) {
    .return-modalContent {
        max-width: 70vw;
    }
}
@media screen and (max-width: 900px) {
    .return-modalContent {
        max-width: 75vw;
    }
    .order-return-input {
        display: grid;
        justify-items: right;
        align-items: baseline;
    }
    .order-return-input input {
        grid-row: 2/3;
    }
}
.green-btn:disabled {
    background: grey;
}
/* ----------------------newtransfer --------------- */

.transfer-wrapper {
    margin: 1rem;
    display: grid;
}

.backbutton {
    width: 5rem;
    height: 2rem;
    border: 1px solid lightgray;
    margin-bottom: 1rem;
    cursor: pointer;
}
.transfer-wrapper .inputs {
    display: flex;
    /* display: flex; */
    /* justify-content: space-around; */
    flex-wrap: wrap;
    padding: 1rem;
    gap: 1rem;
}
.transfer-wrapper .inputs button {
    padding: 0 1rem;
    border: none;
    /* margin: 0.5rem; */
    background-color: rgba(12, 12, 181, 0.601);
    color: white;
}
.transfer-wrapper .inputs input,
.transfer-wrapper .inputs select {
    /* width: fit-content; */
    text-indent: 0.5rem;
    max-width: 100px;
    height: 2.5rem;
}
.transfer-wrapper .inputs select {
    /* width: 100%; */
    max-width: 250px;
    text-indent: 0;
    /* padding: 0 1rem 0 0; */
}
.transfer-wrapper .form-control-add {
    position: relative;
}

.transfer-wrapper .form-control-add input {
    border: 1px solid gray;
    border-radius: 6px;
    position: relative;
    width: 100%;
    /* margin: 10px; */
    line-height: 6ex;
}
.transfer-submit {
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    padding: 0.3rem 0.5rem;

    background-color: rgba(12, 12, 181, 0.601);
    color: white;
    border: 1px solid rgba(12, 12, 181, 0.601);
}
.transfer-wrapper .form-control-add label {
    position: absolute;
    top: -10px;
    z-index: 1;
    font-size: 0.8rem;
    width: fit-content;
    left: 6px;
    background-color: white;
    padding: 0 5px;
}
.transfer-wrapper .table-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.transfer-wrapper .table-wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.transfer-wrapper .table-wrapper {
    display: grid;
    justify-items: center;
    margin-top: 1rem;
    margin: 1rem;
    /* margin: 1rem 1rem 0 1rem; */
    /* min-width: 900px; */
    width: 98%;
    overflow: scroll;
}
.transfer-wrapper .table-wrapper table {
    /* padding: 1rem; */
    width: 100%;
}
.transfer-wrapper .table-wrapper thead tr {
    background-color: antiquewhite;
}
.transfer-wrapper .table-wrapper tr th {
    padding: 1rem 0.5rem;
    margin: 0.5rem;
    border: 0.5px solid grey;
    font-size: 0.8rem;
    text-align: center;
    text-transform: uppercase;
}
.transfer-wrapper .table-wrapper tbody tr {
    border: 0.5px solid grey;
}
.transfer-wrapper .table-wrapper tbody tr td {
    text-align: center;
    padding: 1rem 0;
    font-size: 0.8rem;
    border: 0.5px solid grey;
}
.transfer-wrapper .table-wrapper tbody tr td button {
    border: none;
    background-color: inherit;
}
.ledger-form-control {
    display: grid;
    gap: 1rem;
    grid-template-columns: 10% 1fr;
    align-items: center;
}
.ledger-form-control label {
    font-size: 1.2rem;
}
.ledger-form-control textarea {
    resize: none;
    outline: none;
    border-radius: 0.5rem;
    border: 1px solid lightgray;
    padding: 0.5rem;
}
.ledger-form-control input {
    text-indent: 0.5rem;
    height: 3rem;
    outline: none;
    border-radius: 0.5rem;
    border: 1px solid lightgray;
}
.ledger-modalContent {
    height: unset;
}
.ledger-modalContent form h4 {
    text-align: center;
}
.ledger-modalContent form button {
    width: fit-content;
    align-self: center;
    justify-self: center;
}
.ledger-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.ledger-data button {
    height: 3rem;
    background-color: rgba(14, 159, 110, 1);
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: 500ms;
    padding: 0 1rem;
}
.category-form-two select {
    height: 2.8rem;
    width: 100%;
    max-width: 15rem;

    height: 3.2rem;
    text-indent: 1rem;
    outline: none;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;

    border: 1px solid rgba(229, 231, 235, 1);
}
.supplier-filter {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}
/* ----------------homepage -sections------------ */
.homepage-section-wrapper {
    background-color: white;
    gap: 1rem;
    border: 1px solid #d5d6d7;
    border-radius: 10px;
    /* padding: 1rem; */
    box-shadow: 0px 4px 60px rgb(0 0 0 / 8%);
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
}
.homepage-section-wrapper button {
    border-radius: 10px;
    border: 1px solid #d5d6d7;
    color: white;
    background-color: white;
    padding: 1rem;
}
.homepage-section-wrapper button:hover {
    opacity: 0.8;
}
.claim-btn {
    border: 1px solid #ccc;
    background-color: inherit;
    padding: 0.5rem;
}
.searchHit_Customer {
    width: fit-content;
    background-color: white !important;
    display: grid;
    gap: 0.3rem;
    border: 1px solid #d5d6d7;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}
.searchHit_Customer span {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}
.searchHit_Customer p {
    margin-bottom: 0;
}
.warehouseFilter_all {
    position: relative;
}
.warehouseFilter_all button {
    display: none;
    position: absolute;
    width: 1rem;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    right: 5px;
    top: 15px;
    background-color: red;
    height: 1rem;
    border-radius: 50%;
    transition: all 0.5s ease;
}
.warehouseFilter_all button:hover {
    background-color: red;
}
.warehouseFilter_all:hover button {
    display: flex;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}
.modal-content {
    display: grid;
    /* justify-items: center; */
    gap: 2rem;
    opacity: 1;
    max-width: 600px;
    width: 100%;
    padding: 2rem 0;
    background: #ffffff;
    border-radius: 14px;
    position: relative;
}
.spinner {
    display: grid;
    /* justify-items: center; */
    gap: 2rem;
    opacity: 1;
    /* max-width: 600px; */
    justify-items: center;
    width: 100%;
    padding: 2rem 0;
    /* background: #ffffff; */
    border-radius: 14px;
    position: relative;
}
.modal-content .close-btn {
    position: absolute;
    right: 3px;
    border-top-right-radius: 14px;
    top: -32px;
    border: none;
    color: #103178;
    background-color: white;
}
.modal-content p {
    /* font-family: "Roboto", sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 53px;
    text-align: center;

    color: #1f0f34;
}
.control-btns {
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.control-btns button {
    /* font-family: "Roboto", sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    text-align: center;
    /* padding: 0.6rem 4rem; */
    border-radius: 5px;
    height: 2.5rem;
    width: 5rem;
    cursor: pointer;
}
.control-btns .cancel-btn {
    background: #ffffff;
    color: #103178;
    border: 1px solid #103178;
}

.control-btns .cancel-btn:active {
    background-color: #f5f5f5;
    /* border: none; */
}
.control-btns .delete-btn {
    border: none;
    background: red;
    color: #ffffff;
}
.form-control {
    display: grid;
    gap: 0rem;
    margin-bottom: 1rem;
    justify-items: center;
}
.form-control label {
    margin-bottom: 1rem;
    color: #24262d;
    font-size: 1.4rem;
}
.error {
    color: rgba(249, 128, 128, 1) !important;
    font-size: 1.1rem !important;
    font-weight: inherit !important;
    margin: 0;
}
.form-control textarea {
    resize: none;
    width: 70%;
    text-indent: 1rem;
    padding: 0.5rem 1rem;
    outline: none;
    color: black;
    /* font-family: "Roboto", sans-serif; */
    font-size: 1.1rem;
}
.re-order {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: right;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.re-order button {
    cursor: pointer;
    /* font-family: "Roboto", sans-serif; */
    font-weight: 600;
    color: #103178;
    background-color: inherit;
    border: 1px solid #103178;
    padding: 0.5rem 0;
    font-size: 1.2rem;
    width: 13rem;
}
.re-order button:hover {
    background-color: #103178;
    color: white;
}
@media screen and (max-width: 790px) {
    .control-btns button {
        padding: 0.5rem 4rem;
    }
    .emptyCart-wrapper {
        padding: 1rem;
    }
}
@media screen and (max-width: 800px) {
    .modal-content {
        gap: 1rem;
        margin: 1rem;
        height: fit-content;
        padding: 0.1rem;
    }
    .modal-content .close-btn {
        top: -15px;
    }
    .modal-content p {
        font-size: 18px;
    }
    .control-btns button {
        font-size: 14px;
        padding: 0.5rem 2rem;
    }
}
.cancel-msg {
    font-size: 1rem !important;
    line-height: 2rem !important;
    justify-self: center;
    padding: 0 2rem;
    margin: 0 0 1rem 0 !important;
    color: red !important;
}

* {
    margin: 0;
    padding: 0;
}
.TopNav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
    padding: 1rem 3rem;
    z-index: 10;
    /* position: fixed; */
    top: 0;
    right: 0;
    width: 100%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.user {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.user p {
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
}
.user p span {
    font-weight: 550;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.notifications {
    position: relative;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}
.notifications > p {
    position: absolute;
    top: -0.3rem;
    left: -0.5rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: white;
    background-color: red;
    height: 1.2rem;
    display: grid;
    align-items: center;
    justify-items: center;
    width: 1.2rem;
    border-radius: 50%;
}
.user img {
    width: 2.3rem;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.user-notifications {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 2.1rem;
    left: -21rem;
    height: 22rem;
    width: 22rem;
    z-index: 100000;
    background-color: white;

    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}
.user-notifications::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.user-notifications::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.user-notifications::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.user-notifications::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.item {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid rgba(229, 231, 235, 1);
}
.item:hover {
    background-color: rgba(249, 250, 251, 1);
}
.item-data {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.item-data .desc {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(112, 114, 117, 1);
    font-size: 14px;
    font-weight: 600;
}
.item-icon {
    border-radius: 50%;
    border: 1px solid grey;
}

.item-data .stockout {
    color: #f05252;
    font-size: 11px;
    background-color: #fde8e8;
    width: fit-content;
    padding: 0.3rem;
    border-radius: 10px;
    font-weight: 600;
}
.item-data .NewOrder {
    color: #0e9f6e;
    font-size: 11px;
    background-color: #def7ec;
    width: fit-content;
    padding: 0.3rem;
    border-radius: 10px;
    font-weight: 600;
}
.date-time {
    color: rgba(112, 114, 117, 1);
    font-size: 13px;
    padding: 0 1rem;
}
@media screen and (max-width: 500px) {
    .user-notifications {
        height: 16rem;
        width: 17rem;
        left: -15rem;
    }
    .item-data .desc {
        width: 60%;
    }
    .date-time {
        padding: 0 0.5rem;
    }
}
/* -----------sideNav------------- */
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 1);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}
.spinner {
    display: grid;
    /* justify-items: center; */
    gap: 2rem;
    opacity: 1;
    /* max-width: 600px; */
    justify-items: center;
    width: 100%;
    padding: 2rem 0;
    /* background: #ffffff; */
    border-radius: 14px;
    position: relative;
}
.sidenavwrapper {
    z-index: 100;
    background-color: white !important;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
    /* position: fixed; */
    min-height: 100vh;
    height: 100%;
    overflow: scroll;
}
.sidenavbar {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    /* transition: 1s; */
    height: 100%;
    /* position: relative; */
}
.logo {
    /* align-self: center; */
    display: flex;
    align-items: flex-end;
    gap: 0.3rem;
    outline: none;
    border: none;
    background-color: white;
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
    opacity: 0.8;
    margin-left: 1.3rem;
    padding: 1rem 0 0 0;
}
.logo-icon {
    align-self: flex-start;
}
.Navlinks {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.Navlinks a,
.seo-item {
    display: flex;
    text-decoration: none;
    align-items: center;
    gap: 1rem;
    font-weight: 500;
    font-size: 0.9rem;
    font-family: "Open Sans", sans-serif;

    padding: 0rem 1rem 0 1.3rem;
}
.seo {
    display: grid;
    gap: 1rem;
    transition: 1s;
    /* overflow: scroll; */
}
.seo-item {
    padding: 0rem 1rem 0 1.6rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: 1s;
    gap: 0.4rem;
    /* overflow: scroll; */
}
.seo-item-nav {
    display: grid;
    gap: 0.8rem;
}
.seo-item p {
    color: #707275;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 0.9rem;
    font-family: "Open Sans", sans-serif;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.Navlinks a span {
    margin-top: 0.4rem;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.nav-inactive {
    color: #707275;
    border-left: 4px solid white;
}
.nav-active {
    color: #0e9f6e;
    border-left: 4px solid #0e9f6e;
}
.Navlinks a:hover {
    color: #046c4e;
}
.logout {
    align-self: center;
    display: flex;
    padding: 2rem 0;
    height: 100%;
}
.logout button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: white;
    background-color: #0e9f6e;
    border: none;
    font-size: 0.9rem;
    height: 2rem;
    width: 8rem;
    /* padding: 0.8rem 3rem; */
    cursor: pointer;
    margin-right: 1rem;
    border-radius: 5px;
}
.logout button:hover {
    background-color: #046c4e;
}
.logo-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin: 0 1rem 1rem 0;
}
.logout-short {
    display: flex;
    text-decoration: none;
    align-items: center;
    gap: 1rem;
    font-weight: 500;
    font-size: 0.9rem;
    font-family: "Open Sans", sans-serif;

    padding: 0rem 2rem 0 1.3rem;
}
.logout-short button {
    color: #707275;
    background-color: white;
    padding: 1rem 0.4rem;
    border: none;
}
.logo-wrap button {
    border: none;
    background-color: inherit;
    width: fit-content;
}
.nav-icon {
    font-size: 1.2rem;
}
.sidenavwrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidenavwrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media screen and (max-width: 786px) {
    .sidenavwrapper {
        /* width: 100%; */

        width: fit-content;
        height: 100%;
        z-index: 10000;
        gap: 3rem;
    }

    .seo-item p {
        display: none;
    }
    .logout button {
        display: flex;
        text-decoration: none;
        align-items: center;
        font-size: 0.8rem;
        margin: 0;
        padding: 0rem;
        color: #707275;
        background-color: white;
    }
    .wrapper-show {
        z-index: 10000;
        background-color: white;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
        min-height: 100vh;
        height: 100%;
        width: 280px;
        /* transition: 1s; */
        z-index: 1000;
    }

    .seo-item {
        padding: 0.6rem 1.2rem;
    }
    .Navlinks a {
        padding: 0.3rem 1rem;
    }

    .nav-icon-logout {
        display: flex;
        text-decoration: none;
        align-items: center;
        font-size: 1rem;
        padding: 0.6rem 2rem;
        color: #707275;
    }
}
